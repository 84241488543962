import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Cheeky Chicken and Grill is a popular takeaway restaurant located in
        Egham, Surrey. The restaurant specializes in serving a variety of
        mouth-watering dishes made from fresh ingredients. The menu features a
        wide range of options.
      </Typography>
      <Typography variant="body1" paragraph>
        One of the standout features of Cheeky Chicken and Grill is their
        dedication to providing customers with high-quality food and excellent
        service. The staff are friendly and attentive, ensuring that all orders
        are prepared with care and delivered to customers promptly. The
        restaurant also offers a convenient online ordering system, allowing
        customers to place their orders easily and quickly. Additionally, the
        restaurant provides a delivery service, ensuring that customers can
        enjoy their meals from the comfort of their own homes.
      </Typography>
      <Typography variant="body1" paragraph>
        Overall, Cheeky Chicken and Grill is a must-try takeaway option in Egham
        for anyone who loves delicious, fresh food and excellent service.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
